<template>
  <v-dialog
    v-model="dlg.visible"
    width="500"
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card
        class="white pa-6"
        flat
      >
        <v-text-field
          v-model="dlg.content"
          label="转发后显示的标题"
          outlined
          hide-details
        />
      </v-card>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          width="80"
          @click="onConfirm"
        >
          {{ btnOk.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api/co.api'
  export default {
    data () {
      return {
        btnClose: {
          icon: 'mdi-close'
        },
        dlg: {
          title: '内容转发',
          visible: false,
          content: ''
        },
        btnOk: {
          text: '转发'
        },
        opts: {}
      }
    },
    methods: {
      show (options) {
        this.opts = options
        this.dlg.title = `将转发《${options.title}》`
        this.dlg.visible = true
      },
      onClose () {
        this.dlg.visible = false
      },
      onConfirm () {
        const content = api.comm.trim(this.dlg.content)
        if (!content) {
          return
        }
        this.$emit('confirm', {
          index: this.opts.index,
          item: this.opts.item,
          content
        })
        this.onClose()
      },
    }
  }
</script>
